import { Helmet } from "react-helmet-async";
import Layout from "../../components/global/Layout/Layout";
import SectionRoadmap from "../../components/roadmap/SectionRoadmap/SectionRoadmap";
import CardLesson from "../../components/roadmap/CardLesson/CardLesson";
import { backendDasarData, backendLanjutanData, frontendDasarData, frontendLanjutanData, studiKasusFullstackJamstackData, studiKasusFullstackMonolithData, pengenalanPemrogramanData, bonusKelasKarierData, bonusKelasHostingData } from "../../data/roadmaps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faStar } from "@fortawesome/free-solid-svg-icons";

const Roadmaps = () => {
    return (
        <>
            <Helmet>
                <title>Roadmaps - KelasFullstack.id</title>
            </Helmet>
            <Layout>
                <SectionRoadmap
                    title={<><span className="text-warning">Roadmap</span> Belajar</>}
                    subtitle="Berikut ini rencana pengembangan Kurikulum dalam program KelasFullstack.id"
                >
                    <div className="d-flex gap-3 align-items-center">
                        <FontAwesomeIcon className="text-warning" size="xl" icon={faStar} />
                        <h4 className="fw-bold m-0">Pengenalan <span className="text-warning">Pemrograman</span></h4>
                    </div>
                    <div className="row my-5">
                        {pengenalanPemrogramanData.map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4 mb-4">
                                    <CardLesson
                                        title={item.title}
                                        duration={item.total_duration}
                                        lesson={item.total_lesson}
                                        type={item.type}
                                        percentage={item.percentage}
                                        tag={item.tag}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </SectionRoadmap>
                <SectionRoadmap
                    title={<>Menjadi <span className="text-warning">Front End Developer</span></>}
                >
                    <div className="d-flex gap-3 align-items-center">
                        <FontAwesomeIcon className="text-warning" size="xl" icon={faStar} />
                        <h4 className="fw-bold m-0"><span className="text-warning">Front End</span> Dasar</h4>
                    </div>
                    <div className="row my-5">
                        {frontendDasarData.map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4 mb-4">
                                    <CardLesson
                                        title={item.title}
                                        duration={item.total_duration}
                                        lesson={item.total_lesson}
                                        type={item.type}
                                        percentage={item.percentage}
                                        tag={item.tag}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                        <FontAwesomeIcon className="text-warning" size="xl" icon={faStar} />
                        <h4 className="fw-bold m-0"><span className="text-warning">Front End</span> Lanjutan</h4>
                    </div>
                    <div className="row my-5">
                        {frontendLanjutanData.map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4 mb-4">
                                    <CardLesson
                                        title={item.title}
                                        duration={item.total_duration}
                                        lesson={item.total_lesson}
                                        type={item.type}
                                        percentage={item.percentage}
                                        tag={item.tag}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </SectionRoadmap>
                <SectionRoadmap
                    title={<>Menjadi <span className="text-warning">Back End Developer</span></>}
                >
                    <div className="d-flex gap-3 align-items-center">
                        <FontAwesomeIcon className="text-warning" size="xl" icon={faStar} />
                        <h4 className="fw-bold m-0"><span className="text-warning">Back End</span> Dasar</h4>
                    </div>
                    <div className="row my-5">
                        {backendDasarData.map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4 mb-4">
                                    <CardLesson
                                        title={item.title}
                                        duration={item.total_duration}
                                        lesson={item.total_lesson}
                                        type={item.type}
                                        percentage={item.percentage}
                                        tag={item.tag}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                        <FontAwesomeIcon className="text-warning" size="xl" icon={faStar} />
                        <h4 className="fw-bold m-0"><span className="text-warning">Back End</span> Lanjutan</h4>
                    </div>
                    <div className="row my-5">
                        {backendLanjutanData.map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4 mb-4">
                                    <CardLesson
                                        title={item.title}
                                        duration={item.total_duration}
                                        lesson={item.total_lesson}
                                        type={item.type}
                                        percentage={item.percentage}
                                        tag={item.tag}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </SectionRoadmap>
                <SectionRoadmap
                    title={<>Menjadi <span className="text-warning">Full Stack Monolith</span> Developer</>}
                >
                    <div className="d-flex gap-3 align-items-center">
                        <FontAwesomeIcon className="text-warning" size="xl" icon={faStar} />
                        <h4 className="fw-bold m-0">Studi Kasus <span className="text-warning">Full Stack Monolith</span></h4>
                    </div>
                    <div className="row my-5">
                        {studiKasusFullstackMonolithData.map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4 mb-4">
                                    <CardLesson
                                        title={item.title}
                                        duration={item.total_duration}
                                        lesson={item.total_lesson}
                                        type={item.type}
                                        percentage={item.percentage}
                                        tag={item.tag}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </SectionRoadmap>
                <SectionRoadmap
                    title={<>Menjadi <span className="text-warning">Full Stack Jam Stack</span> Developer</>}
                >
                    <div className="d-flex gap-3 align-items-center">
                        <FontAwesomeIcon className="text-warning" size="xl" icon={faStar} />
                        <h4 className="fw-bold m-0">Studi Kasus <span className="text-warning">Full Stack Jam Stack</span></h4>
                    </div>
                    <div className="row my-5">
                        {studiKasusFullstackJamstackData.map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4 mb-4">
                                    <CardLesson
                                        title={item.title}
                                        duration={item.total_duration}
                                        lesson={item.total_lesson}
                                        type={item.type}
                                        percentage={item.percentage}
                                        tag={item.tag}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </SectionRoadmap>
                <SectionRoadmap
                    title={<>Kelas <span className="text-warning">Bonus <FontAwesomeIcon icon={faBullhorn} /></span></>}
                >
                    <div className="d-flex gap-3 align-items-center">
                        <FontAwesomeIcon className="text-warning" size="xl" icon={faStar} />
                        <h4 className="fw-bold m-0">Karier</h4>
                    </div>
                    <div className="row my-5">
                        {bonusKelasKarierData.map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4 mb-4">
                                    <CardLesson
                                        title={item.title}
                                        duration={item.total_duration}
                                        lesson={item.total_lesson}
                                        type={item.type}
                                        percentage={item.percentage}
                                        tag={item.tag}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                        <FontAwesomeIcon className="text-warning" size="xl" icon={faStar} />
                        <h4 className="fw-bold m-0">Hosting</h4>
                    </div>
                    <div className="row my-5">
                        {bonusKelasHostingData.map((item, index) => {
                            return (
                                <div key={index} className="col-lg-4 mb-4">
                                    <CardLesson
                                        title={item.title}
                                        duration={item.total_duration}
                                        lesson={item.total_lesson}
                                        type={item.type}
                                        percentage={item.percentage}
                                        tag={item.tag}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </SectionRoadmap>
            </Layout>
        </>
    );
};

export default Roadmaps;