import { faRightToBracket, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Hamburger from "hamburger-react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styles from './Navbar.module.scss';

const Navbar = () => {
    const [isOpen, setOpen] = useState(false);
    const location = useLocation();
    const linkJoinProgram = location.pathname.includes("/promo") ? "#invest" : "/#invest";
    return (
        <nav className="navbar navbar-dark navbar-expand-lg bg-secondary fixed-top shadow">
            <div className="container py-2 px-4 px-lg-5">
                <HashLink className="navbar-brand" to="/#top">
                    <img className={styles.img_logo} src="/assets/img/fullstack-logo.webp" alt="Kelas Fullstack" />
                </HashLink>
                <button className="navbar-toggler shadow-0 border-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <Hamburger color="#ffcc31" size={23} toggled={isOpen} toggle={setOpen} title="menu" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 me-lg-3 mb-lg-0">
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname === '/roadmap' ? 'fw-bolder text-warning' : 'fw-bolder'}`} to="/roadmap">Lihat Kurikulum</Link>
                        </li>
                    </ul>
                    <div className="d-grid gap-2 d-md-block">
                        <a className="btn btn-outline-warning rounded-1 me-md-3 px-3 py-2" href="https://www.codepolitan.com/login" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="me-2" icon={faRightToBracket} />
                            Login Member
                        </a>
                        <HashLink className="btn btn-warning rounded-1 px-3 py-2" to={linkJoinProgram}>
                            <FontAwesomeIcon className="me-2" icon={faUserPlus} />
                            Gabung Program
                        </HashLink>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
