import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faMap, faPlayCircle, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Modal from "../../global/Modal/Modal";

import styles from './Hero.module.scss';
import { Link } from "react-router-dom";

const Hero = () => {
    const location = useLocation();
    const linkJoinProgram = location.pathname.includes("/promo") ? "#invest" : "/#invest";
    return (
        <>
            <section className={`${styles.hero} text-white pt-5`}>
                <div className="container py-5 px-4 px-lg-5">
                    <div className="row justify-content-between">
                        <div className="col-lg-5 my-auto">
                            <h1 className="section-title fw-bold mb-4">Jalan Lurus Jadi <span className="text-warning">Fullstack Web Developer</span></h1>
                            <p className="lh-lg mb-4">Kelas online belajar Fullstack web developer from A to Z. Cocok buat yang pengen punya karir bagus, skill dibutuhin industri, gaji tinggi dan bisa bikin web atau aplikasi buat ngembangin bisnis online sendiri.</p>
                            <p className="lh-lg mb-4">Aman buat pemula walaupun belum ngerti coding dan nggak punya background IT. Diajarin dari 0 banget!</p>
                            <p className="fw-bolder mb-4">2000+ orang udah belajar di sini.</p>
                            <div className="d-grid gap-2 d-xl-flex  my-4">
                                <HashLink className="btn btn-warning rounded-1 px-3 py-2" to={linkJoinProgram}>
                                    <FontAwesomeIcon className="me-2" icon={faUserPlus} />
                                    Gabung Program
                                </HashLink>
                                <a className="btn btn-outline-warning rounded-1 px-3 py-2" href="https://wa.me/6285175484822?text=Hai%20Kelasfullstack%2C%20Saya%20ingin%20bergabung%20dengan%20program%20kelasfullstack%2C%20bisa%20tolong%20dibantu%3F" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon className="me-2" icon={faWhatsapp} />
                                    Tanya CS
                                </a>
                                <Link className="btn btn-outline-warning rounded-1 px-3 py-2" to="/roadmap">
                                    <FontAwesomeIcon className="me-2" icon={faMap} />
                                    Kurikulum
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-7 my-auto p-0">
                            <div className={styles.img_wrapper}>
                                <img className="img-fluid d-block mx-auto" src="/assets/img/img-hero-2.webp" alt="Hero" />
                                <div className={styles.img_overlay}>
                                    <button type="button" className={`btn bg-white rounded-circle shadow-lg p-1 ${styles.btn_play}`} data-bs-toggle="modal" data-bs-target="#heroModal" title="Play">
                                        <FontAwesomeIcon size="4x" className="text-warning" icon={faPlayCircle} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                id="heroModal"
                title="Teaser"
                modalSize="modal-xl"
            >
                <div className="ratio ratio-16x9">
                    <iframe src="https://www.youtube.com/embed/FgZBeR1q-44?rel=0" title="YouTube video" loading="lazy" allowFullScreen />
                </div>
            </Modal>
        </>
    );
};

export default Hero;
